import React, { useEffect } from "react";
import Close from "../Assets/close_1828665 1.svg";
import axios from "axios";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

function SuccessPayment() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [paymentCheck, setPaymentCheck] = React.useState(true);
  const paymentId = searchParams.get("payment_intent");
  const [loading, setLoading] = React.useState(true);
  const paymentStatus = async () => {
    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/get-status-by-payment-id`,
        {
          payment_id: paymentId,
          // user_id:
        },
        {
          headers: {
            content: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log(response.data);
      // localStorage.removeItem("payment_id");
      if (response.data === "succeeded") {
        // localStorage.removeItem("payment_id")
        setPaymentCheck(true)
        //  localStorage.removeItem("bookingName")
      } else {
        setPaymentCheck(false)
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setPaymentCheck(false)
      setLoading(false);
      // message.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (paymentId) {
      paymentStatus();
    } else {
      window.location.href = "/"
    }
  }, []);
  return (
    <>
      {
        loading && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "110px", height: "500px" }}>
          <LoadingOutlined style={{ fontSize: "50px", height: "50px", width: "50px" }} />
        </div>
      }
      {
        !loading &&
        <>
          {paymentCheck ?
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "110px",
              }}
            >
              <div
                style={{
                  boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.15)",
                  padding: "71px 31px 134px 38px",
                  width: "80%",
                }}
              >
                <div
                  style={{
                    borderRadius: "133px",
                    background: "#D1FADF",
                    width: "200px",
                    height: "200px",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src="/images/Icon.png" />
                </div>
                {/* <div style={styles.text}>Payment Successfull.</div> */}
                <div style={styles.text}>Your payment done successfully!</div>
              </div>
            </div> :
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "110px" }}>
              <div style={{
                boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.15)",
                padding: "71px 31px 134px 38px",
                width: "80%"
              }}>
                <div style={{
                  borderRadius: "133px",
                  background: "#FEEBEA",
                  width: "200px",
                  height: "200px",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <img src={Close} alt="close" />
                </div>
                <div style={styles.text}>Payment Failed.</div>
                <div style={styles.text}>Please review your payment details and try again.</div>
              </div>
            </div>}
        </>
      }
    </>
  );
}

export default SuccessPayment;

const styles = {
  text: {
    fontSize: "32px",
    fontWeight: "bold",
    textAlign: "center",
  },
};
